import { apiInstace } from "../../server";

const getOrderDetails = async (id) => {
  try {
    const data = await apiInstace.get(`/orders/order_profits/${id}`);
    console.log({ order_profits: data });
    return data.data;
  } catch (error) {
    console.log(error);
  } finally {
  }
};

export const getOrderProfitByOrder = async (order) => {
  try {
    const data = await apiInstace.get(`/orders/order_profits`, { params: { order } });
    console.log({ order_profits: data });
    return data.data?.results?.[0];
  } catch (error) {
    console.log(error);
  } finally {
  }
};

export default getOrderDetails;
